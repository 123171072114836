import Work1 from '../../assets/work-1.png';


const Menu = [
    {
      id: 1,
      image: Work1,
      title: "Portfolio",
      category: "Web App",
      link:"https://sbdev.me"
    },

  ];
  
export default Menu